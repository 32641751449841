import { useMemo, useState } from "react"
import styled from "styled-components"

import { subWeeks } from "date-fns"

import { UpgradeComponentBlocker } from "src/components/FeatureBlockers/UpgradeComponentBlocker"
import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { useTrackAnalyticsFilterApplied } from "src/data/analytics/queries/moldAnalyticsQueries"
import {
  TReportAnalyticsContext,
  TReportFilterType,
} from "src/data/analytics/types/analyticsTypes"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import {
  DropdownSelect,
  TSelectGroupOption,
  TSelectOption,
} from "src/ui/DropdownSelect/DropdownSelect"
import { isGroupSelectOption } from "src/ui/DropdownSelect/utils"
import { spacing } from "src/ui/spacing"

export function InsightFilters({
  dateRange,
  setDateRange,
  selectedOption,
  setSelectedOption,
  hidden,
  disabled = false,
  showUpgradeBlocker,
  context,
  requiredPlan,
  isDateBlocked,
  intervalMs,
  maxSelectableDays,
}: {
  dateRange: IGraphDateRange
  setDateRange: (dateRange: IGraphDateRange) => void
  selectedOption: TSelectOption<string> | null
  setSelectedOption: (option: TSelectOption<string> | null) => void
  hidden?: boolean
  disabled?: boolean
  showUpgradeBlocker?: boolean
  context: TReportAnalyticsContext
  requiredPlan?: TPlan
  isDateBlocked?: (date: Date) => boolean
  intervalMs?: number
  maxSelectableDays?: number
}) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()

  const [searchText, setSearchText] = useState("")

  const postAnalyticsFilterApplied = useTrackAnalyticsFilterApplied()

  const fetchHomegroups = useFetchHomeGroups({
    orgId,
    filter: { name: searchText ? searchText : undefined },
  })

  const groupedOptions: TSelectGroupOption[] = useMemo(() => {
    const homegroups = fetchHomegroups.data?.homegroups ?? []

    const buildingOptions = homegroups.filter((hg) => hg.type === "building")
    const locationOptions = homegroups.filter((hg) => hg.type === "area")
    return [
      {
        title: t(langKeys.home_group_building_plural),
        options: buildingOptions.map((hg) => ({
          label: hg.name,
          value: hg.id,
        })),
      },
      {
        title: t(langKeys.organizations_home_group_plural),
        options: locationOptions.map((hg) => ({
          label: hg.name,
          value: hg.id,
        })),
      },
    ]
  }, [
    fetchHomegroups.data?.homegroups,
    langKeys.home_group_building_plural,
    langKeys.organizations_home_group_plural,
    t,
  ])

  const filteredGroupOptions: TSelectGroupOption[] = useMemo(() => {
    const mapped = groupedOptions.map((opt) => {
      if (isGroupSelectOption(opt)) {
        return {
          ...opt,
          options: opt.options.filter((opt) =>
            opt.label.toLowerCase().includes(searchText.toLowerCase())
          ),
        }
      }
      return opt
    })

    return mapped.filter((option) => {
      if (isGroupSelectOption(option)) {
        return option.options.length > 0
      }
      return (option as TSelectOption).label
        .toLowerCase()
        .includes(searchText.toLowerCase())
    })
  }, [groupedOptions, searchText])

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })

    postAnalyticsFilterApplied.mutate({
      context,
      filter_type: "date",
    })
  }

  function handleHomegroupChange(
    option: TSelectOption<string, TSelectGroupOption>
  ) {
    setSelectedOption(option)

    const filterLabel = filteredGroupOptions
      .find((o) => o.options.find((opt) => opt.value === option.value))
      ?.title.toLowerCase()

    postAnalyticsFilterApplied.mutate({
      context,
      filter_type: isValidReportFilterType(filterLabel)
        ? filterLabel
        : undefined,
    })
  }

  if (hidden) {
    return null
  }

  return (
    <FilterBox>
      <UpgradeComponentBlocker
        eventProperties={{ context, type: "filter" }}
        showUpgradeBlocker={showUpgradeBlocker && disabled}
        requiredPlan={requiredPlan}
      >
        <DropdownSelect
          label={
            selectedOption?.label ??
            `${t(langKeys.building)}/${t(langKeys.plan_renewal_estimate_breakdown_location)}`
          }
          options={filteredGroupOptions}
          selectedValue={selectedOption?.value || ""}
          onChange={({ option }) => handleHomegroupChange(option)}
          searchText={searchText}
          onSearch={setSearchText}
          placeholder={t(langKeys.search)}
          onClearSelection={() => {
            setSelectedOption(null)
            setSearchText("")
          }}
          loading={fetchHomegroups.isLoading}
          disabled={disabled}
        />
      </UpgradeComponentBlocker>

      <UpgradeComponentBlocker
        eventProperties={{ context }}
        showUpgradeBlocker={showUpgradeBlocker && disabled}
        requiredPlan={requiredPlan}
      >
        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          intervalMs={intervalMs}
          dateRangeProps={{
            minBookingDays: 30,
            isDateBlocked,
          }}
          maxSelectableDays={maxSelectableDays}
          onSelectPreset={({ clearSelection }) => {
            if (clearSelection) {
              setDateRange({
                startDate: subWeeks(new Date(), 5),
                endDate: new Date(),
              })
            }
          }}
          defaultPresetKey="DAY"
          calendarPlacement="top-end"
          hidePresets={["DAY", "WEEK", "YEAR"]}
          disabled={disabled}
        />
      </UpgradeComponentBlocker>
    </FilterBox>
  )
}

function isValidReportFilterType(
  filter: string | undefined
): filter is TReportFilterType {
  return ["building", "area", "location"].includes(filter ?? "")
}

const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XS};
`
