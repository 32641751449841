import { useMemo, useState } from "react"

import { useStorage } from "src/data/storage/useStorage"
import {
  getTimezoneByTzCode,
  getTimezones,
  TTimezone,
} from "src/data/timezones/timezones"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { getStoredSelectedTimezone } from "src/utils/l10n"

export function TimezoneSelect({
  timezone,
  onChange,
  includeEtc = false,
}: {
  timezone: TTimezone
  onChange: (timezone: TTimezone) => void
  includeEtc?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const [searchText, setSearchText] = useState("")

  // Filter out the Etc/GMT+x timezones, nobody will ever use them
  const timezoneOptions = useMemo(
    () =>
      getTimezones().filter((tz) =>
        includeEtc ? true : !tz.tzCode.includes("Etc")
      ),
    [includeEtc]
  )
  const { timezoneStorage } = getStoredSelectedTimezone()

  const selectedTimeZoneTzcode = timezone.tzCode

  const { set: setStoredTimezone } = useStorage({
    storage: timezoneStorage,
    initialValue: timezone.tzCode,
  })

  const selectedTimezone = timezoneOptions.find(
    (option) => option.tzCode === selectedTimeZoneTzcode
  )

  const label = selectedTimezone
    ? selectedTimezone.name.replace("_", " ")
    : t(langKeys.timezone)

  const filteredOptions = useMemo(
    () =>
      timezoneOptions
        .filter((tz) =>
          tz.label.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((tz) => ({
          label: tz.label,
          value: tz.tzCode,
        })),
    [searchText, timezoneOptions]
  )

  return (
    <DropdownSelect
      label={label}
      options={filteredOptions}
      selectedValue={selectedTimeZoneTzcode}
      searchText={searchText}
      onSearch={setSearchText}
      onChange={({ option }) => {
        const selectedTimezone = timezoneOptions.find(
          (tz) => tz.tzCode === option.value
        )
        if (selectedTimezone) {
          onChange(selectedTimezone)
          setStoredTimezone(option.value)
        }
      }}
      placeholder={t(langKeys.timezone)}
      mButtonVariant="subtle"
      onClearSelection={() => {
        const defaultTimezone = getTimezoneByTzCode(
          Intl.DateTimeFormat().resolvedOptions().timeZone
        )

        if (defaultTimezone) {
          onChange(defaultTimezone)
          setStoredTimezone(defaultTimezone.tzCode)
        }
      }}
    />
  )
}
